// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-biography-page-js": () => import("./../../../src/templates/biography-page.js" /* webpackChunkName: "component---src-templates-biography-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-infographics-and-maps-page-js": () => import("./../../../src/templates/infographics-and-maps-page.js" /* webpackChunkName: "component---src-templates-infographics-and-maps-page-js" */),
  "component---src-templates-language-skills-page-js": () => import("./../../../src/templates/language-skills-page.js" /* webpackChunkName: "component---src-templates-language-skills-page-js" */),
  "component---src-templates-media-page-js": () => import("./../../../src/templates/media-page.js" /* webpackChunkName: "component---src-templates-media-page-js" */),
  "component---src-templates-publications-page-js": () => import("./../../../src/templates/publications-page.js" /* webpackChunkName: "component---src-templates-publications-page-js" */),
  "component---src-templates-research-page-js": () => import("./../../../src/templates/research-page.js" /* webpackChunkName: "component---src-templates-research-page-js" */),
  "component---src-templates-teaching-page-js": () => import("./../../../src/templates/teaching-page.js" /* webpackChunkName: "component---src-templates-teaching-page-js" */),
  "component---src-templates-technical-skills-page-js": () => import("./../../../src/templates/technical-skills-page.js" /* webpackChunkName: "component---src-templates-technical-skills-page-js" */)
}

